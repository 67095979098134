<template lang="pug">
section.partners(v-if="records.length > 0")
  .partners__wrapper
    .container.framed
      h2 We work with the nation's most innovative retailers

      .partners__grid
        div(
          v-for="i, k in records"
          :key="k"
          v-image="i && i.logo ? i.logo.url : null")

    .partners__actions
      nuxt-link.el-button.el-button--highlight(
        v-if="!$store.getters['authentication/isLogged']"
        :to="{ name: 'register' }"
        v-click="() => $amplitude.track('HOMEPAGE_REGISTER', { zone: 'partners-section' })"
        title="Sign up now") Sign up now
</template>

<script>
export default {
  props: {
    records: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.partners {
  position: relative;
  margin: 96px 0 0;
  z-index: 1;
  padding-bottom: 24px;
  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 30%;
    bottom: 0;
    left: 0;
    right: 0;
    background: $--color-alternative;
  }
  h2 {
    text-align: center;
    margin: 0 auto;
    max-width: 640px;
  }
  &__wrapper {
    position: relative;
    background: white;
    margin: 0 auto;
    padding: 96px 0;
    @include media(md) {
      border-radius: 24px;
      max-width: calc(100% - 48px);
    }
  }
  &__actions {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    bottom: -24px;
    .el-button {
      min-width: 160px;
    }
  }
  &__grid {
    display: grid;
    margin-top: 64px;
    gap: 16px 32px;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    @include media(md) {
      gap: 32px 36px;
      grid-template-columns: repeat(auto-fit, minmax(156px, 1fr));
    }
    > * {
      min-width: 0;
      aspect-ratio: 2;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      // filter: grayscale(1);
      // transition: filter .2s ease-in-out;
      // &:hover {
      //   filter: grayscale(0);
      // }
    }
  }
}
</style>
