<template lang="pug">
.bokeh-backdrop(:class="{ 'bokeh-backdrop--static': static }")
  svg(viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg")
    circle(cx="10%" cy="85%" r="75%")
    circle(cx="45%" cy="50%" r="15%")
    circle(cx="85%" cy="35%" r="30%")
    circle(cx="60%" cy="85%" r="20%")
    circle(cx="45%" cy="50%" r="30%")
    circle(cx="35%" cy="25%" r="20%")
    circle(cx="90%" cy="-25%" r="35%")
    circle(cx="-15%" cy="30%" r="30%")
    circle(cx="65%" cy="85%" r="55%")
    circle(cx="45%" cy="50%" r="20%")
</template>

<script>
export default {
  props: {
    static: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.bokeh-backdrop {
  &--static {
    svg {
      circle {
        animation-play-state: paused !important;
      }
    }
  }
  svg {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #f8f9fb;
    filter: blur(48px);
    transform: translateZ(0);
    backface-visibility: hidden;
    $blue: #E5E5F8;
    $pink: #F5DFEF;
    circle {
      animation: rotate 6s linear infinite;
      fill: $blue;
      transform-origin: 50%;
      &:nth-child(2n) {
        fill: $pink;
        transform-origin: 20% 110%;
        animation-delay: -3s;
        animation-duration: 4s;
      }
      &:nth-child(3n) {
        // fill: $color3;
        transform-origin: 70% 30%;
        animation-delay: -1.5s;
        animation-duration: 7s;
      }
      &:nth-child(4n) {
        // fill: $color4;
        transform-origin: 110% 70%;
        animation-delay: -2s;
        animation-duration: 5s;
      }
      &:nth-child(5n) {
        // fill: $color5;
        transform-origin: 30% 40%;
      }
      &:nth-child(6n) {
        fill: $blue;
        transform-origin: -10% 80%;
        animation-delay: -8s;
      }
      &:nth-child(7n) {
        fill: $pink;
        transform-origin: 70% -10%;
      }
      &:nth-child(8n) {
        // fill: $color4;
        transform-origin: -30% 50%;
        animation-delay: -2.4s;
      }
    }
  }
}
</style>
